import React from "react";
import Layout from "../../components/layout";
import Banner from "../../components/banner";
import { graphql } from "gatsby";
import ServicePageContent from "../../components/service-page-content";
import NotifyMeForm from "../../components/notify-me-form";
import Section from "../../components/section";
import wrapWithLayout from "../../components/layout/wrapWithLayout";
// eslint-disable-next-line no-unused-vars
import * as PageSeoFragment from "../../fragments/pageSeo";
// eslint-disable-next-line no-unused-vars
import * as productFragment from "../../fragments/product";
import { CheckoutForm } from "../../components/checkout";

const SpiritualAwakeningCoachingPage = wrapWithLayout(({ data }) => {
  const banner = data?.strapiSpiritualAwakeningCoachingPage?.banner;
  const content = data?.strapiSpiritualAwakeningCoachingPage?.Content;
  const product = data?.strapiSpiritualAwakeningCoachingPage?.product;

  return (
    <>
      {banner && <Banner {...banner} />}
      <div className="container">
        {content && <ServicePageContent {...content} />}
        <Section title={"Course Offerings"} alignTitle={"center"}>
          <div className="mt-3">
            <p>
              I offer a range of life coaching courses designed to meet you
              wherever you are on your journey:
            </p>{" "}
            <ul className="mt-2">
              <li>
                <strong>Awaken Your Spirit:</strong> A course focused on
                spiritual growth, mindfulness, and connecting with your inner
                self.
              </li>
              <li>
                <strong>Personal Empowerment:</strong> This course helps you
                build confidence, overcome obstacles, and become the best
                version of yourself.
              </li>
              <li>
                <strong>Holistic Wellness:</strong> A comprehensive approach to
                wellness that covers physical, emotional, and spiritual
                well-being.
              </li>
              <li>
                <strong>Fulfillment and Balance:</strong> Learn how to set
                meaningful goals, pursue your passions, and create a balanced,
                fulfilling life.
              </li>
            </ul>
            <p>
              Join me on this transformative journey and unlock the incredible
              potential within you. With my guidance, you will awaken your
              spirit, become empowered, and live a life of purpose and
              fulfillment. I look forward to walking this path with you.
            </p>
          </div>
        </Section>
        <CheckoutForm
          returnUrlSlug={"spiritual-awakening-coaching"}
          product={product}
        />
      </div>
      <div className="mt-4">
        <NotifyMeForm product={product} />
      </div>
    </>
  );
});

export default SpiritualAwakeningCoachingPage;

export const query = graphql`
  query {
    strapiSpiritualAwakeningCoachingPage {
      seo {
        ...PageSeoFragment
      }
      banner {
        ...BannerFragment
      }
      Content {
        ...ServicePageContentFragment
      }
      product {
        ...ProductFragment
      }
    }
  }
`;
