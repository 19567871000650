import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const Section = (props) => {
  const { children, title, subtitle, alignTitle = "start" } = props;
  const className = props.className ? ` ${props.className}` : "";
  return (
    <section className={`c-section py-4 py-md-5 ${className}`}>
      {title && <h2 className={`text-${alignTitle} display-4`}>{title}</h2>}
      {subtitle && <h5 className="fw-lighter">{subtitle}</h5>}
      <div className="mt-3 mt-md-4">{children}</div>
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alignTitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Section;
